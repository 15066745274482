<template>
  <div class="time-line-container">
      <time-item v-for="item in value" :key="item.id" :value="item" />
  </div>
</template>

<script>
import TimeItem from '@/components/TimeLine/TimeItem.vue'

export default {
  name: 'TimeLine',
  components: { TimeItem },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.time-line-container {}
</style>
