<template>
  <div :class="`${prefixCls}-honors`" id="aochenHonors">
    <Separate title="资质荣誉" desc="QUALIFICATIONS HONORS" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-honors-content`">
        <time-line style="margin-left: 50px" :value="honorList" />
<!--      <custom-card class="card" v-for="item in honorList" :key="item.id" :value="item" @click="handleClick" />-->
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { siteNewsPage } from '@/api/index'
import TimeLine from '@/components/TimeLine/index.vue'
export default {
  name: 'QualificationsHonors',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      honorList: [
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    TimeLine,
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    siteNewsPage () {
      siteNewsPage({ channelId: '13' }, { channelId: 0 }).then(({ data }) => {
        this.honorList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -company-honors;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  flex-grow: 1;
  &-content{
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    .card {
      margin: 0 20px 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
