<template>
  <div class="time-item-card">
      <div class="left-layout">
          <div class="circle" />
          <div class="line" />
      </div>
      <div class="right-layout">
          <span class="date">{{ `${value.title}【${format(value.publishTime)}】` }}</span>
          <span class="content">{{ value.content }}</span>
      </div>
  </div>
</template>

<script>
import { validatenull } from '@/utils/validate'
import moment from 'moment'
export default {
  name: 'TimeItem',
  filters: {
    format (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy年MM月DD日')
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    format (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy年MM月DD日')
    }
  }
}
</script>

<style lang="scss" scoped>
.time-item-card {
  display: flex;
  width: 100%;
  height: 125px;
  .left-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #3176f5;;
    }
    .line {
      position: relative;
      width: 0;
      height: 100%;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 7px;
        right: 0;
        bottom: 0;
        width: 1px;
        border-width: 0 0 0 1px;
        border-color: #3176f5;;
        border-style: dashed;
        margin: auto;
      }
    }
  }
  .right-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -5px;
    left: 15px;
    font-family: arial !important;
    font-size: 16px !important;
    color: #191818 !important;
    line-height: 40px !important;
    font-weight: lighter !important;
    .date {
      //font-size: 22px;
      //font-weight: bold;
      text-align: left;
      color: #333;
    }
    .content {
      margin-top: 15px;
      text-align: left;
      margin-left: 12px;
      //font-size: 18px;
      //color: #707b7c;
    }
  }
}
</style>
