import { render, staticRenderFns } from "./honors.vue?vue&type=template&id=36b471c3&scoped=true&"
import script from "./honors.vue?vue&type=script&lang=js&"
export * from "./honors.vue?vue&type=script&lang=js&"
import style0 from "./honors.vue?vue&type=style&index=0&id=36b471c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b471c3",
  null
  
)

export default component.exports